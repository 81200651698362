import './bootstrap.min.css'
import "./styles.css";
import { FiImage } from "react-icons/fi";
import { Button, Image} from 'react-bootstrap';
import Loader from "./Loader";
import { useRef, useState, useEffect } from "react";
import axios from "axios";

export default function App() {

  const imagePicker = useRef(null);

  const [uploading, setUploading] = useState(false);
  const [message, setMessage] = useState("");
  const [previewImage, setPreviewImage] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [uploadedURL, setUploadedURL] = useState(null);


  useEffect(() => {
    const timer = setTimeout(() => setMessage("  "), 500000);
    return () => clearTimeout(timer);
  }, [message]);

  const onSelectFile = (event) => {
    let reader = new FileReader();
    const file = event.target.files[0];
    // console.log(file, typeof file);
    reader.onloadend = (e) => {
      setPreviewImage(reader.result);
    };
    reader.readAsDataURL(file);
    setSelectedFile(file);
  };

const uploadImage = async () => {
    try {
      const formData = new FormData();
      formData.append("file", selectedFile);
      formData.append("upload_preset", "clientphotos");
      setUploading(true);
      const response = await axios.post(
        "https://api.cloudinary.com/v1_1/dqs2eaipw/image/upload",
        formData
      );
      setUploadedURL(response.data.secure_url);
      setPreviewImage(null);
      setSelectedFile(null);
      setUploading(false)
      setMessage("上傳成功 Uploaded Success :)")
      setTimeout(function(){ document.location.reload(); }, 3000);
    } catch (error) {
      console.error(error);
      setMessage("出了些問題 Something went wrong :(")
      setTimeout(function(){ document.location.reload(); }, 3000);
    }
  };

  function removeImage () {
    setPreviewImage(null)
    document.getElementById('uploadbutton').style.visibility = 'hidden';
    document.location.reload(true);
  }

  return (
    <div>
      <div className="App">
      <div className="topimage"><Image src="https://res.cloudinary.com/dqs2eaipw/image/upload/v1637164461/missmirage/pngfind.com-meme-png-6930614_olucud.png" alt="missmiragewaiting" fluid/></div>
      <h3 style={{textAlign: "center"}}>Miss Mirage</h3>
      <br></br>
      <div style={{textAlign: "center"}}>請點擊下方圖標以上傳您的照片</div>
      <div style={{textAlign: "center"}}>Please click the icon below to upload your photo</div>
      <div className="container-imagePicker">
        <FiImage onClick={() => imagePicker.current.click()} />
        <input
          type="file"
          accept="image/png, image/jpeg, image/jpg, image/heic, image/heif"
          multiple={false}
          id="input-imagePicker"
          ref={imagePicker}
          onChange={onSelectFile}
        />
      </div>
      {uploadedURL ? (<div className="alert">{message}</div>):(<div className="alert">{message}</div>)}

      {!uploading ? (
      <div className="buttongroup">
      {selectedFile && <Button variant="danger" id="uploadbutton" className="button" onClick={() => uploadImage()}>確認上傳 Upload</Button>}
      {previewImage && (
        <div>
          <Button className="button" variant="warning" onClick={() => removeImage()}>移除 Remove</Button>
        </div>
      )}
      </div>):(<></>)}


      {uploading ? (<Loader/>):(
      <div>
      {previewImage && (
        <div className="previewimage">
        <div style={{textAlign: "center", marginTop: "15px", marginBottom: "15px"}}>
          預覽 Preview</div>
        <div className="imageborder"><Image src={previewImage} alt="selected" fluid/></div>
        </div>
      )}
      </div>)}
      </div>
    </div>
  );
}
